import { Button, AddMd, RemoveMd } from '@rea-group/construct-kit-core';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  span {
    font-weight: 600;
    font-size: 16px;
  }
`;

const AdditionalTargeting = ({ showHideColm, btntext, changeText }) => {
  return (
    <div className="TargetBtnContainer">
      <StyledButton
        id="addmore"
        icon={btntext === 'More' ? <AddMd /> : <RemoveMd />}
        iconPlacement="left"
        name="addmore"
        variant="link-primary"
        sizeVariant="small"
        onClick={() => {
          showHideColm();
          changeText(btntext === 'More' ? 'Less' : 'More');
        }}
      >
        {btntext + ' targeting options'}
      </StyledButton>
    </div>
  );
};

export default AdditionalTargeting;
