import { TextInput } from '@rea-group/construct-kit-core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateInput = ({
  id,
  name,
  className,
  label,
  value,
  onChange,
  disabled,
  sizeVariant,
}) => {
  const dateCheck = (value) => {
    if (value === '') {
      return '';
    } else {
      return new Date(value);
    }
  };
  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      selected={dateCheck(value ?? '')}
      onChange={(date) => {
        if (date) {
          onChange(date.toISOString().slice(0, 10));
        } else {
          onChange('');
        }
      }}
      disabled={disabled}
      customInput={
        <TextInput
          id={id}
          type="text"
          name={name}
          label={label}
          hideLabel={true}
          onChange={onChange}
          className={className}
          sizeVariant={sizeVariant}
          value={value}
        />
      }
    />
  );
};

export default DateInput;
