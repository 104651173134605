import { Select } from '@rea-group/construct-kit-core';
const LobSelection = ({ lob, setLob }) => {
  const lobs = [
    {
      value: 'Developer Sales - NSW',
      label: 'Developer',
    },
    {
      value: 'Developer Sales - WA',
      label: 'Developer WA',
    },
    {
      value: 'Media Sales - NSW',
      label: 'Media',
    },
    {
      value: 'n/a',
      label: 'N/A',
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setLob(selectedOption);
    localStorage.setItem('QA_LOB', JSON.stringify(selectedOption));
  };

  return (
    <div className="topInputContainer">
      <Select
        onSelectedOptionChange={handleOptionChange}
        options={lobs}
        defaultSelectedOption={lob}
        label="Select LOB"
        sizeVariant="small"
      />
    </div>
  );
};
export default LobSelection;
