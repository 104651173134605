import { Autocomplete, ListboxOption } from '@rea-group/construct-kit-core';
import { useState } from 'react';
import styled from 'styled-components';

const StyledAutocomplete = styled(Autocomplete)`
  ul {
    width: 300%;
    border-top: 0.125rem solid var(--ck-borderFocused);
    border-radius: 0rem 0rem 0rem 0rem;
    margin-top: 1px;
  }

  div[role='combobox'] {
    border-radius: 0.5rem !important;
  }
`;

const ProductBox = ({ id, idx, name, value, lob, handleChange, disabled }) => {
  const [productOptions, setProductOptions] = useState([]);

  const PRODUCT_SUGGEST_API = `${process.env.REACT_APP_QUICK_AVAILS_API}/auto_complete_product`;

  const fetchProductSuggestions = (productQuery) => {
    const postData = { query: productQuery, team: lob.value };
    let urlparms = new URLSearchParams(postData).toString();
    const url = `${PRODUCT_SUGGEST_API}`;

    return fetch(url + '?' + urlparms)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  };

  const onInputChange = (val) => {
    if (val && val.length > 2) {
      fetchProductSuggestions(val).then((fetchedSuggestions) => {
        setProductOptions(fetchedSuggestions);
      });
    }
  };

  const mapOptionToReact = ({
    option,
    isActive,
    sizeVariant,
    inputValue,
    mapOptionToString,
  }) => {
    return (
      <ListboxOption isActive={isActive} sizeVariant={sizeVariant}>
        <span title={product_price_str(option)}>
          {product_price_str(option)}
        </span>
      </ListboxOption>
    );
  };
  const mapOptionToStr = (option) => {
    if (!option) return '';
    return option.name.toString();
  };

  const product_price_str = (option) => {
    if (!option) return '';
    let product_name = option.name;
    product_name = product_name.replaceAll("'", "\\'");
    let list_rate = parseFloat(option.list_rate).toFixed(2);
    return `${product_name} - $${list_rate}`.toString();
  };

  return (
    <StyledAutocomplete
      id={id}
      name={name}
      hideLabel={true}
      label={name}
      placeholder={'Product Name'}
      onInputChange={(value) => {
        onInputChange(value);
        handleChange(value, idx);
      }}
      filterOptions={(e) => {
        return e;
      }}
      listboxMaxHeight="40rem"
      mapOptionToListNode={mapOptionToReact}
      onSelectedOptionChange={(option) => {
        handleChange(option.name, idx);
      }}
      mapOptionToString={mapOptionToStr}
      options={productOptions}
      sizeVariant="small"
      inputValue={value}
      disabled={disabled}
    />
  );
};
export default ProductBox;
