import { useState, useEffect } from 'react';
import {
  Modal,
  Dialog,
  Card,
  spacingHelper,
  Button,
  Inline,
  Small,
  H2,
  Strong,
} from '@rea-group/construct-kit-core';
import { v4 as uuidv4 } from 'uuid';
import Footer from './Footer';
import Header from './Header';
import LobSelection from './LobSelect';
import GetTargetDetails from './GetTargetDetails';
import BatchAvails from './BatchAvails';
import AdditionalTargeting from './AdditionalTargeting';
import Table from './Table';
import styled from 'styled-components';

const ContentContainer = styled(Card)`
  padding: ${spacingHelper('medium')};
  width: 800px;
`;

const StyledDialogBox = styled(Dialog)`
  max-width: none;
`;
const Body = () => {
  const today = new Date();

  const start_date = new Date(today);
  start_date.setDate(start_date.getDate() + 1);

  const end_date = new Date(today);
  end_date.setDate(end_date.getDate() + 28);

  let backgroundRequests = [];

  //modal toggle
  const [isModalOpen, setIsModalOpen] = useState(false);

  //initial state of table
  const [tableRows, setTableRows] = useState({
    rows: [
      {
        id: uuidv4(),
        start_date: start_date.toISOString().substring(0, 10),
        end_date: end_date.toISOString().substring(0, 10),
        state: '',
        product:
          'REA Buy - Search Results - Responsive Native Single Image - Suburb',
        targeting_1: '',
        targeting_2: '',
        targeting_3: '',
        targeting_4: '',
        total: '',
        available: '',
        contending: '',
        deleteBtnDisplay: 'none',
        runBtnDisplay: 'inline-block',
        processed: false,
      },
    ],
  });

  const [tableHeader, setTableHeader] = useState([
    {
      targeting_3: 'TARGETING 3',
      show: localStorage?.getItem('targ_toggle_val')
        ? localStorage.getItem('targ_toggle_val')
        : 'none',
    },
    {
      targeting_4: 'TARGETING 4',
      show: localStorage?.getItem('targ_toggle_val')
        ? localStorage.getItem('targ_toggle_val')
        : 'none',
    },
  ]);

  const [btntext, setButtonText] = useState(
    localStorage?.getItem('targ_toggle_val') === 'table-cell' ? 'Less' : 'More', //initial button value
  );

  const [batchText, setBatchText] = useState('');

  //lob state
  let lsLOB = null;
  if (localStorage?.getItem('QA_LOB')) {
    lsLOB = JSON.parse(localStorage.getItem('QA_LOB'));
  } else {
    lsLOB = { label: 'N/A', value: 'n/a' };
  }
  const [lob, setLob] = useState(lsLOB);

  const [targetList, setTargetList] = useState({ type: '', results: [] });

  const get_target_details = (val) => {
    let searchParam = val;

    //launch modal
    if (typeof val.type !== 'undefined' && val.type !== '') {
      if (val.type === 'proptype') {
        return;
      }
      searchParam = val.type + '=' + val.value;
    } else {
      if (val.indexOf('=') === -1) {
        //default to suburb
        searchParam = 'sub=' + val;
      }
    }

    let urlparms = new URLSearchParams(searchParam).toString();

    fetch(
      `${process.env.REACT_APP_QUICK_AVAILS_API}/target_details?` + urlparms,
    )
      .then((response) => response.json())
      .then((data) => {
        setTargetList(data);
        setIsModalOpen(true);
      });
  };

  const copytobatchavails = () => {
    let result_values = '';
    //let state_result_values = '';

    targetList.results.forEach((item) => {
      if (result_values !== '') {
        result_values += ',';
      }

      if (targetList.type === 'target') {
        item.subname.split(',').forEach((sub) => {
          //result_values+= "sub=";
          result_values += sub + ',';
        });
      } else {
        item.region.split(',').forEach((region) => {
          result_values += 'target=';
          result_values += region + ',';
        });
      }
    });

    //$('#list_of_subs').val(result_values.replace(/,/g, '\n'));
    //$('[id^=state_]').val(state_result_values);

    setBatchText(result_values.replace(/,/g, '\n'));

    setIsModalOpen(false);
  };

  useEffect(() => {
    batchAvails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchText]);

  const showHideColm = () => {
    const ls_value = localStorage?.getItem('targ_toggle_val')
      ? localStorage.getItem('targ_toggle_val')
      : 'none';
    const set_value =
      ls_value === 'table-cell'
        ? 'none'
        : ls_value === 'none'
          ? 'table-cell'
          : 'none';
    let display =
      set_value === 'table-cell' ? '' : set_value === 'none' ? 'none' : '';

    setTableHeader([
      { targeting_3: 'TARGETING 3', show: (tableHeader[0].show = display) },
      { targeting_4: 'TARGETING 4', show: (tableHeader[1].show = display) },
    ]);

    //set localStorage value.
    localStorage?.setItem('targ_toggle_val', set_value);
  };

  //we need the last line of the table.
  const batchAvails = () => {
    if (batchText === '') return;

    //get last row
    //clear last row
    let rows = tableRows.rows;
    let idx = rows.length - 1;
    let last_row = rows[idx];

    //get product
    if (rows[idx].product === '') {
      alert('Please specify a product!');
      return;
    }

    let batchSuburbs = batchText.split('\n');

    if (batchSuburbs[0].indexOf('target=') >= 0) {
      //clear state
      rows[idx].state = '';
    }

    //remove first rows
    rows.splice(idx, 1);
    //update table state
    setTableRows({
      rows: rows,
    });

    for (var i = 0; i < batchSuburbs.length && i < 60; i++) {
      if (batchSuburbs[i] !== '') {
        let batch_line = batchSuburbs[i];
        if (batch_line.indexOf('=') === -1) {
          batch_line = 'sub=' + batch_line;
        }

        const item = {
          id: uuidv4(),
          start_date: last_row.start_date,
          end_date: last_row.end_date,
          product: last_row.product,
          state: last_row.state,
          targeting_1: batch_line,
          targeting_2: '',
          targeting_3: '',
          targeting_4: '',
          total: 'Processing',
          available: '',
          contending: '',
          deleteBtnDisplay: 'none',
          runBtnDisplay: 'none',
          isDisabled: true,
          processed: false,
        };

        rows.push(item);

        getQuickAvails(item.id);
      }
    }

    //update table state
    setTableRows({
      rows: rows,
    });

    //Add new row
    handleAddRow();

    if (batchSuburbs.length > 60) {
      alert(
        'Batch Avails can only run 60 requests at a time.\nNot all of your requests have been processed',
      );
    }
  };

  const getQuickAvails = (request_id) => {
    const {
      start_date,
      end_date,
      state,
      product,
      targeting_1,
      targeting_2,
      targeting_3,
      targeting_4,
    } = tableRows.rows.find((x) => x.id === request_id);

    setTableRows((currentState) => {
      const newState = Object.assign({}, currentState);
      const newStateRow = newState.rows.find((x) => x.id === request_id);
      if (!newStateRow) return currentState;
      newStateRow.total = 'Processing';
      newStateRow.isDisabled = true;
      newStateRow.runBtnDisplay = 'none';
      return newState;
    });

    const postData = {
      start_date: start_date,
      end_date: end_date,
      product: product,
      state: state,
      targeting_1: targeting_1,
      targeting_2: targeting_2,
      targeting_3: targeting_3,
      targeting_4: targeting_4,
    };

    make_initial_request(postData, request_id);

    let rows = tableRows.rows;
    let idx = rows.length - 1;
    let last_row = rows[idx].isDisabled;
    if (last_row) {
      handleAddRow();
    }
  };

  const make_initial_request = async (postData, request_id) => {
    const urlparms = new URLSearchParams(postData).toString();
    const url = `${process.env.REACT_APP_QUICK_AVAILS_API}/run_avails`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      cache: 'no-cache',
      body: urlparms,
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data != 'undefined') {
          if (typeof data.Contending != 'undefined') {
            const contending_link = data.Contending;

            setTableRows((currentState) => {
              const newState = Object.assign({}, currentState);
              const newStateRow = newState.rows.find(
                (x) => x.id === request_id,
              );
              if (!newStateRow) return currentState;
              newStateRow.contending = contending_link;
              return newState;
            });
          }

          if (typeof data.error != 'undefined') {
            if (data.error === 'Unknown response') {
              //Likely a request limit being hit. Let's retry this in 20ish seconds
              setTableRows((currentState) => {
                const newState = Object.assign({}, currentState);
                const newStateRow = newState.rows.find(
                  (x) => x.id === request_id,
                );
                if (!newStateRow) return currentState;
                newStateRow.total = 'Requests throttled. Retrying shortly.';
                return newState;
              });

              setTimeout(function () {
                make_initial_request(postData, request_id);
              }, 10000);
            } else {
              setTableRows((currentState) => {
                const newState = Object.assign({}, currentState);
                const newStateRow = newState.rows.find(
                  (x) => x.id === request_id,
                );
                if (!newStateRow) return currentState;
                newStateRow.total = data.error;
                newStateRow.isDisabled = false;

                //newStateRow.processed = false;
                newStateRow.runBtnDisplay = 'inline-block';
                return newState;
              });

              if (
                data.error === "You don't have permission to view this page"
              ) {
                //offerLogin();
              }
            }
          } else if (typeof data.job_id != 'undefined') {
            setTableRows((currentState) => {
              const newState = Object.assign({}, currentState);
              const newStateRow = newState.rows.find(
                (x) => x.id === request_id,
              );
              if (!newStateRow) return currentState;
              newStateRow.total = 'Running';
              return newState;
            });
            //job retry
            backgroundRequests[data.job_id] = 0;
            setTimeout(function () {
              background_check(request_id, data.job_id);
            }, 10000);
          } else {
            //update tablerow state.
            setTableRows((currentState) => {
              const newState = Object.assign({}, currentState);
              const newStateRow = newState.rows.find(
                (x) => x.id === request_id,
              );
              if (!newStateRow) return currentState;
              newStateRow.total = data.Total;
              newStateRow.processed = true;

              //display delete btn, hide current row Run btn & add new row.
              newStateRow.deleteBtnDisplay = 'inline-block';
              newStateRow.runBtnDisplay = 'none';
              newStateRow.available = data.Available;
              return newState;
            });
          }
        }
      });
  };

  const background_check = (request_id, job_id) => {
    const postData = { job_id: job_id };
    let wait_time = 3000;
    backgroundRequests[job_id]++;

    if (backgroundRequests[job_id] > 7) {
      wait_time = 8000;
      setTableRows((currentState) => {
        const newState = Object.assign({}, currentState);
        const newStateRow = newState.rows.find((x) => x.id === request_id);
        if (!newStateRow) return currentState;
        newStateRow.total = 'Taking longer than expected';
        return newState;
      });
    }
    if (backgroundRequests[job_id] > 14) {
      wait_time = 12000;
      setTableRows((currentState) => {
        const newState = Object.assign({}, currentState);
        const newStateRow = newState.rows.find((x) => x.id === request_id);
        if (!newStateRow) return currentState;
        newStateRow.total = 'Taking much longer than expected';
        return newState;
      });
    }
    if (backgroundRequests[job_id] > 30) {
      setTableRows((currentState) => {
        const newState = Object.assign({}, currentState);
        const newStateRow = newState.rows.find((x) => x.id === request_id);
        if (!newStateRow) return currentState;
        newStateRow.total = 'Cancelled';
        newStateRow.isDisabled = false;
        return newState;
      });
      return;
    }

    const urlparms = new URLSearchParams(postData).toString();
    const url = `${process.env.REACT_APP_QUICK_AVAILS_API}/background`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      cache: 'no-cache',
      body: urlparms,
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data != 'undefined') {
          if (typeof data.error != 'undefined') {
            setTableRows((currentState) => {
              const newState = Object.assign({}, currentState);
              const newStateRow = newState.rows.find(
                (x) => x.id === request_id,
              );
              if (!newStateRow) return currentState;
              newStateRow.total = data.error;
              newStateRow.isDisabled = false;

              //newStateRow.processed = false;
              newStateRow.runBtnDisplay = 'inline-block';
              return newState;
            });
            if (data.error === "You don't have permission to view this page") {
              //offerLogin();
              alert('Login Required');
            }
          } else if (typeof data.job_id != 'undefined') {
            setTimeout(function () {
              background_check(request_id, job_id);
            }, wait_time);
          } else {
            if (data.Status !== 'completed') {
              setTimeout(function () {
                background_check(request_id, job_id);
              }, wait_time);
            } else {
              //update tablerow state.
              setTableRows((currentState) => {
                const newState = Object.assign({}, currentState);
                const newStateRow = newState.rows.find(
                  (x) => x.id === request_id,
                );
                if (!newStateRow) return currentState;

                newStateRow.total = data.Total;
                newStateRow.processed = true;

                newStateRow.deleteBtnDisplay = 'inline-block';
                newStateRow.runBtnDisplay = 'none';
                newStateRow.available = data.Available;

                return newState;
              });
            }
          }
        }
      });
  };

  const handleAddRow = () => {
    const item = {
      id: uuidv4(),
      start_date:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].start_date
          : '',
      end_date:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].end_date
          : '',
      product:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].product
          : '',
      state:
        tableRows.rows.length > 0
          ? tableRows.rows[tableRows.rows.length - 1].state
          : '',
      targeting_1: '',
      targeting_2: '',
      targeting_3: '',
      targeting_4: '',
      total: '',
      available: '',
      contending: '',
      deleteBtnDisplay: 'none',
      runBtnDisplay: 'inline-block',
      processed: false,
    };
    setTableRows({
      rows: [...tableRows.rows, item],
    });
  };

  return (
    <div className="container">
      <div className="pageTop">
        <Header />
        <div className="topContainer">
          <Inline alignItems="flex-start" gap="medium">
            <LobSelection lob={lob} setLob={setLob} />
            <GetTargetDetails getTargetDetails={get_target_details} />
          </Inline>
        </div>
        <div className="flexBreak">
          <Table
            lob={lob}
            getTargetDetails={get_target_details}
            setTableRows={setTableRows}
            tableRows={tableRows}
            getQuickAvails={getQuickAvails}
            tableHeader={tableHeader}
          />
        </div>
        <AdditionalTargeting
          showHideColm={showHideColm}
          btntext={btntext}
          changeText={setButtonText}
        />
      </div>
      <div className="pageBottom">
        <div className="flexBreak">
          <BatchAvails
            batchText={batchText}
            setBatchText={setBatchText}
            batchAvails={batchAvails}
          />
        </div>
        <Footer />
      </div>

      <Modal
        opened={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        getAppElement={() => '.container'}
      >
        <StyledDialogBox
          header={<H2>Suburb/Region/Target</H2>}
          footer={
            <Button
              id="run_btn_batch"
              className="btn btn-outline-danger btn-sm"
              sizeVariant="small"
              onClick={() => copytobatchavails()}
            >
              Run Batch Avails
            </Button>
          }
          autoFullScreen={true}
          headerAlignment="left"
          onClose={() => setIsModalOpen(false)}
        >
          <ContentContainer>
            <table className="batchTable">
              <thead>
                {targetList.type === 'sub' ? (
                  <tr>
                    <th className="table-header" width="200px">
                      <Small>
                        <Strong>Suburb</Strong>
                      </Small>
                    </th>
                    <th className="table-header" width="60px">
                      <Small>
                        <Strong>State</Strong>
                      </Small>
                    </th>
                    <th className="table-header">
                      <Small>
                        <Strong>Region(s)</Strong>
                      </Small>
                    </th>
                  </tr>
                ) : (
                  <tr>
                    <th className="table-header" width="200px">
                      <Small>
                        <Strong>Region</Strong>
                      </Small>
                    </th>
                    <th className="table-header" width="60px">
                      <Small>
                        <Strong>State</Strong>
                      </Small>
                    </th>
                    <th className="table-header">
                      <Small>
                        <Strong>Suburb(s)</Strong>
                      </Small>
                    </th>
                  </tr>
                )}
              </thead>
              <tbody>
                {targetList.results.map((item, idx) => {
                  if (targetList.type === 'sub') {
                    return (
                      <tr key={'map_' + idx}>
                        <td>{item.suburb}</td>
                        <td>{item.state.toUpperCase()}</td>
                        <td>
                          {item.region !== null &&
                            item.region.split(',').map((val, idx) => {
                              return (
                                <div
                                  title={val}
                                  key={item.suburb + '_' + idx}
                                  className="textwrapstyle pill-link"
                                  onClick={(e) => {
                                    get_target_details('target=' + val);
                                  }}
                                >
                                  {val}
                                </div>
                              );
                            })}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr key={'map_' + idx}>
                        <td>{item.region}</td>
                        <td>{item.state.toUpperCase()}</td>
                        <td>
                          {item.region !== null &&
                            item.neatname.split(',').map((val, idx) => {
                              return (
                                <div
                                  title={val}
                                  key={item.region + '_' + idx}
                                  className="textwrapstyle pill-link"
                                  onClick={(e) => {
                                    get_target_details('sub=' + val);
                                  }}
                                >
                                  {val}
                                </div>
                              );
                            })}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </ContentContainer>
        </StyledDialogBox>
      </Modal>
    </div>
  );
};

export default Body;
